import React from "react"
import PropTypes from "prop-types"
import CardCollectionNode from "../components/cardCollectionNode"

const MoreNews = ({ moreNewsData }) => {
  return (
    <CardCollectionNode nodes={moreNewsData.edges} cardStyle="aad-article-teaser" displayMode="slider" ariaLabel="More events" />
  )
}

MoreNews.propTypes = {
  moreNewsData: PropTypes.object.isRequired,
}

export default MoreNews
