import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Container, Row, Col } from "react-bootstrap"
import { contentExists, renderFormattedText, formatDate, ParseText } from "../utils/ug-utils"
import "../components/fragments"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import ShareLinks from "../components/shareLinks"
import MoreNews from "../components/moreNews"
import Tags from "../components/tags"
import "../styles/news.scss"
import HeroSpotlight from "../components/heroSpotlight"
import Breadcrumbs from "../components/breadcrumbs"

const ArticlePage = ({ data, pageContext }) => {
  const { images, articles, moreNews } = data
  const { title, body, field_metatags, field_intro, relationships, changed, created } = articles.edges[0].node
  const tags = relationships.field_tags
  const news_categories = relationships.field_news_category
  const imageData = images.edges
  const heroImage = relationships.field_hero_image
  const ogDescription = contentExists(field_metatags) ? field_metatags.og_description : null
  const ogImage = contentExists(imageData)
    ? imageData[0].node.relationships.field_media_image.localFile.publicURL
    : null
  const ogImageAlt = contentExists(imageData) ? imageData[0].node.field_media_image.alt : null

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "article-page",
          "aria-label": title,
        }}
      />
      <Seo title={title} description={ogDescription} img={ogImage} imgAlt={ogImageAlt} />

      {/**** Header and Title ****/}
      <div id="rotator" className="short-hero image">
        <Hero imgData={[{ node: heroImage }]} />
        <div>
          <HeroSpotlight>
            <h1 className="fancy-title">{title}</h1>
            { typeof field_intro !== 'undefined' && field_intro !== null && <div className="text-white"
              dangerouslySetInnerHTML={{__html: field_intro.processed}}
            />}
          </HeroSpotlight>
        </div>
      </div>

      {/**** Body content ****/}
      <div className="page-container">
        <Container>          
          <div className="breadcrumbs-container">
            <Breadcrumbs targetUrl="/stories" activeTitle={title} />
          </div>
          <dl className="row event-detail">
            <Col lg={8}>
              <Col sm={12} className="content-area">
                {/**** Body content ****/}
                <ParseText textContent={body !== null ? body.processed : ``} />
              </Col>
            </Col>
            <Col lg={4} className="sidebar sidebar-right">
              {/**** Add time ****/}
              <div className="sidebar-item">
                <p className="created-date">
                  Published Date: {formatDate(created)}
                  {changed && (
                    <>
                      <br />
                      Latest Update: {formatDate(changed)}
                    </>
                  )}
                </p>
                <ShareLinks bgStyle={{ fill: "var(--uog-blue-muted)" }} iconFillColor="black" />
                {/**** Tags content ****/}
                {tags?.length > 0 && (
                  <footer className="tags-container pt-3">
                    <h3>Tags</h3>
                    <Tags withId={false} tagData={tags} urlPrefix="/news/tags" />
                  </footer>
                )}
              </div>
            </Col>
          </dl>
        </Container>  
      </div>
    </Layout>
  )
}

export default ArticlePage

export const query = graphql`
  fragment Article on node__article {
    ...Node
    drupal_internal__nid
    path {
      alias
    }
    changed
    created
    field_reading_time
    field_intro {
      processed
    }
    body {
      processed
      summary
    }
    relationships {
      field_news_category {
        ...TaxonomyTerm
      }
      field_cause {
        ...TaxonomyTerm
      }
      field_college {
        ...TaxonomyTerm
      }
      field_hero_image {
        ...HeroImage
      }
      _card_image: field_card_image {
        ...CardImage
      }
      _card_hero_image: field_hero_image {
        ...CardImageHorizontal
      }
    }
  }
  query ($id: String) {
    articles: allNodeArticle(filter: { id: { eq: $id } }) {
      edges {
        node {
          ...Article
        }
      }
    }
    images: allMediaImage(filter: { relationships: { node__article: { elemMatch: { id: { eq: $id } } } } }) {
      edges {
        node {
          ...HeroImage
        }
      }
    }
    moreNews: allNodeArticle(
      limit: 10
      filter: { id: { ne: $id } }
      sort: { fields: [sticky, created], order: [DESC, DESC] }
    ) {
      edges {
        node {
          ...Article
        }
      }
    }
  }
`
